

























































































































































































import {
  defineComponent,
  PropType,
  watch,
  ref,
h
} from '@nuxtjs/composition-api';
import type { CustomerRegistrationFormData } from '@wemade-vsf/checkout/lib/runtime/types';
import {
  SfInput,
  SfButton,
  SfCheckbox
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '@wemade-vsf/customer/lib/runtime/helpers/passwordValidation';

type shippingFormData = CustomerRegistrationFormData['shipping'];

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  props: {
    form: {
      type: Object as PropType<CustomerRegistrationFormData>,
      required: true
    }
  },
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationObserver,
    ValidationProvider
  },
  setup (props, { emit }) {
    const isSeparateShippingAddress = ref<boolean>(false)
    const formData = ref<CustomerRegistrationFormData | undefined>();
    const shippingData = ref<shippingFormData | undefined>();
    watch(() => props.form, (newForm) => { 
      formData.value = { ...newForm }; 
      shippingData.value = { ...newForm.shipping };
    }, { immediate: true, deep: true })

    const onFormSubmit = () => {
      const data = {
        ...formData.value,
        shipping: shippingData.value,
        hasSeparateShippingAddress: isSeparateShippingAddress.value
      }
      emit('submit', data)
    }

    return {
      formData,
      shippingData,
      isSeparateShippingAddress,
      onFormSubmit
    }
  }
})
